<template>
  <div>
    <b-container class="pt-3 pb-3">
      <b-card>
          <div class="text-center">
              <img alt="Tixgo" src="@/assets/logo.svg" class="mb-3" width="120" />
              <h1 class="fw-light">Oeps, je was ons te snel af!</h1>
              <p>
                  De pagina die je zoekt is mogelijk verwijderd, van naam veranderd of tijdelijk niet beschikbaar. Maar misschien kunnen wij je toch verder helpen!
              </p>
              <b-button to="/" variant="primary">Terug naar de homepage</b-button>
          </div>
      </b-card>
    </b-container>
  </div>
</template>

<script>
  export default {
      metaInfo: {
          title: 'Pagina niet gevonden',
      },
      components: {},
  }
</script>